<template>
  <div class="loanDetail">
    <mt-header :title="$t('loanDetail')">
      <router-link v-if="callBack" :to="callBack" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <router-link v-else to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="tips">Dear customer, our repayment channel is only through our APP, corporate account, virtual account only in MONNIFY or in PAYSTACK. Anyone who asks you to repay to a private account, OPAY Or PALMPAY account is fraud, for any enquiries.Please Contact our customer service to avoid been defrauded.</div>
    <div  class="loan_header" v-if="item.contractStatus === 'OVERDUE' || item.contractStatus === 'NORMAL'">
      <div class="loan_header_container">
        <div class="st_item">
          <div class="st_item_status">Repayment Amount</div>
          <div class="st_item_days" @click="popupVisible=true">Details</div>
        </div>
        <div class="loan_header_amount"> ₦  <input type="number" v-model="repaymentAmount"/></div>
        <div class="repay_fee">Including <span class="repay_fee_text">{{mgtFee}}</span> fees charged by payment channels</div>
      </div>
    </div>
    <div v-if="item.contractStatus === 'OVERDUE' || item.contractStatus === 'NORMAL'" class="bottom">
      <div class="foot_amount">₦ {{ repaymentAmount }}</div>
      <div class="foot_button">
        <mt-button type="primary" size="large" @click="$router.push(`/mine/repaymentInfo?applyId=${contractNo}&amount=${repaymentAmount}`)">Repay Now</mt-button>
      </div>
    </div>
  <mt-popup class="plan_popup" v-model="popupVisible"  position="bottom" scrollable="true">
    <div class="plan_box">
      <div class="stages" v-if="item.instalmentMark === 'Y'">
        <div class="item" v-for="(item1, index) in (item.paymentSchedules || [])" :key="index">
          <div class="term_content" >
            <div class="title">Term {{ item1.term}}</div>
            <div class="checkbox">
              <input type="checkbox" name="term" :value="item1.term" v-model="selectedIds" @change="handleChange(item1.term)">
            </div>
          </div>
          <div v-if="item1.term">
            <mt-cell title="Due date">
              {{ item1.loanPmtDueDate}}
            </mt-cell>
            <mt-cell title="Principal">
              ₦ {{ item1.loanTermPrin | formatMoney }}
            </mt-cell>
            <mt-cell title="Interest">
              ₦ {{ item1.loanTermInterest | formatMoney }}
            </mt-cell>
            <mt-cell title="Credit Protection Service">
              ₦ {{ item1.loanTermSvcFee | formatMoney }}
            </mt-cell>
            <mt-cell title="Penalty charge">
              ₦ {{ item1.loanTermPenalty | formatMoney }}
            </mt-cell>
            <mt-cell title="Repayment Amount">
              ₦ {{ item1.loanTermTotalAmt | formatMoney }}
            </mt-cell>
            <mt-cell title="Status">
              {{ item1.currContractStatusName }}
            </mt-cell>
          </div>
        </div>
      </div>
      <div class="stages" v-if="(item.instalmentMark || 'N') === 'N'">
        <div class="item">
          <div>
            <mt-cell title="Due date">
              {{ item.lastTermPmtDueDate}}
            </mt-cell>
            <mt-cell title="Principal">
              ₦  {{ item.loanTermPrin | formatMoney }}
            </mt-cell>
            <mt-cell title="Interest">
              ₦ {{ item.loanTermInterest | formatMoney }}
            </mt-cell>
            <mt-cell title="Credit Protection Service">
              ₦ {{ item.loanTermSvcFee | formatMoney }}
            </mt-cell>
            <mt-cell title="Penalty charge">
              ₦ {{ item.penaltyAmount | formatMoney }}
            </mt-cell>
            <mt-cell title="Repayment Amount">
              ₦  {{ item.loanTermTotalAmt | formatMoney }}
            </mt-cell>
            <mt-cell title="Status">
              {{ item.contractStatusName }}
            </mt-cell>
          </div>
        </div>
      </div>
      <div class="button">
        <mt-button type="primary" size="large" @click="submit">{{
            $t("confirm")
          }}</mt-button>
      </div>
      <!--
      <div class="content">
        <mt-field
            :label="$t('amount')"
            :state="amountState"
            :placeholder="$t('amountPlaceholder')"
            v-model="amount"
        ></mt-field>
      </div>
      <div class="button">
        <mt-button type="primary" size="large" @click="submit">{{
            $t("confirm")
          }}</mt-button>
        <mt-button type="default" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
      </div> -->
    </div>
  </mt-popup>
 </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { contractDetail } from '@/utils/api'
export default {
  name: 'LoanDetail',
  components: {
    callPhone
  },
  computed: {
    contractNo() {
      return this.$route.query.contractNo
    },
    callBack() {
      return this.$route.query.callBack
    }
  },
  data () {
    return {
      item: {},
      mgtFee: '',
      repaymentAmount:'',
      popupVisible: false,
      selectedIds: [],
    }
  },
  watch:{
    'repaymentAmount':{
      handler(val){
        this.mgtFee = (val * this.item.mgtFeeRate).toFixed(2)
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.getContractDetail()
      this.$indicator.close();
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        params: {
          applyId: this.contractNo
        }
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.item = e.body
            console.log(e.body)
            this.repaymentAmount = this.item.currRepayAmt
            this.mgtFee = (this.repaymentAmount * this.item.mgtFeeRate).toFixed(2)
            for (let i = 1; i <= this.item.currTerm; i++) {
              this.selectedIds.push(i)
            }
          }
        })
        .catch(() => {})
    },
    submit() {
      this.popupVisible = false
      if(this.selectedIds.length > 0){
        this.repaymentAmount = this.selectedIds
            .map(id => this.item.paymentSchedules.find(item => item.term === id))
            .filter(Boolean)
            .reduce((sum, item) => sum + item.loanTermTotalAmt, 0).toFixed(2);
      }
    },
    handleChange(value){
      if(!this.selectedIds.includes(value)){
        this.selectedIds = []
        for (let i = 1; i < value; i++) {
          this.selectedIds.push(i)
        }
      } else {
        this.selectedIds = []
        for (let i = 1; i <= value; i++) {
          this.selectedIds.push(i)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loanDetail {
  min-height: 100vh;
  background-image: linear-gradient(to right, #47B526 , #5cd239);
  background-size: cover;
  .tips{
    margin: 20px 25px 20px 25px;
    color: #a63737;
    font-weight: 600;
    text-align: justify;
  }
  .detail {
    width: 16px;
    margin: 0 5px;
  }
  .mint-cell {
    background: rgba(255, 255, 255, 0.8);
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    min-height: 36px;
  }
  .loan_header{
    background-color: #FFFFFF;
    margin: 30px 25px 20px 25px;
    border-radius: 20px;
    height: 160px;
    text-align: center;
    .repay_fee{
      font-size: 14px;
      margin-top: 10px;
      .repay_fee_text{
        color: #d22525;
      }
    }
    .loan_header_container{
      width: 80%;
      margin: 0 auto;
      text-align: left;
    }
    .loan_header_title{
      padding-top: 20px;
    }
    .loan_header_amount{
      font-size: 32px;
      font-weight: 800;
      margin-top: 10px;
      > input{
        height: 40px;
        width: 160px;
        font-size: 22px;
      }
    }
    .nap{
      margin-top: 8px;
      border-top: 2px dashed #777171;
    }
    .st_item{
      display: flex;
      flex-grow: 2;
      padding-top: 20px;
      font-size: 14px;
      .st_item_status{
        float: left;
        width: 50%;
      }
      .st_item_days{
        text-align: right;
        float: right;
        color: #2cb300;
        font-weight: 700;
        width: 50%;
      }
    }
    .date_item{
      display: flex;
      text-align: center;
      margin-top: 10px;
      .item{
        flex-grow: 3;
      }
      .item_date{
        margin-top: 5px;
        font-weight: 600;
      }
    }
  }
  .plan_popup{
    min-height: 300px;
    max-height: 80vh;
    margin-top: 50px;
    width: 100%;
    border-radius: 30px;
    overflow-y: auto; /* 使内容可滑动 */
    .term_content{
      display: flex;
      flex-grow: 2;
      .title{
        width: 50%;
        float: left;
      }
      .checkbox{
        float: right;
        width: 50%;
        margin-right: 10px;
        text-align: right;
      }
    }
  }
  .plan_box{
    .button{
      width: 80%;
      height: 50px;
      margin: 0 auto;
      // background-color: #FFFFFF;
      position: sticky;
      /*黏性定位*/
      bottom: 0;
      /*具体父级元素吸附的距离*/
    }
  }


  .content {
    background-color: #FFFFFF;
    margin: 20px 25px 20px 25px;
    border-radius: 20px;
    .container{
      width: 80%;
      margin: 0 auto;
      text-align: left;
    }
  }
  .stages {
    .item {
      box-shadow: 0px 0px 2px 0px rgba(34, 35, 40, 0.22);
      margin: 10px 20px;
      padding: 10px;
      border-radius: 5px;
      .title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 18px;
      }
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
      .free {
        padding: 5px 10px;
        background: #F15234;
        color: white;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-grow: 2;
    margin: 20px 25px 20px 25px;
    border-radius: 20px;
    background-color: #FFFFFF;
    .foot_amount{
      width: 50%;
      font-size: 20px;
      line-height: 40px;
    }
    .foot_button{
      display: flex;
      width: 50%;
    }
  }
}
</style>
